import axios from "axios";
import store from "../store";
import {Message} from "element-ui";
// 创建axios实例
const service = axios.create({
  timeout: 5000, // 请求超时时间
  headers: {"Content-Type": "application/json;charset=UTF-8"},
});
// axios.defaults.withCredentials = true; // 若跨域请求需要带 cookie 身份识别
// axios.defaults.headers.post["Access-Control-Allow-Origin-Type"] = "*"; // 允许跨域
const err = (error) => {
  if (error.response) {
    let data = error.response.data;
    const token = store.getters.token;
    switch (error.response.status) {
      case 403:
        Message({
          message: data.error,
          type: "error",
          duration: 5 * 1000,
        });
        break;
      case 500:
        Message({
          message: data.error,
          type: "error",
          duration: 5 * 1000,
        });
        break;
      case 404:
        Message({
          message: data.error,
          type: "error",
          duration: 5 * 1000,
        });

        break;
      case 504:
        Message({
          message: data.error,
          type: "error",
          duration: 5 * 1000,
        });
        break;
      case 401:
        Message({
          message: data.error,
          type: "error",
          duration: 5 * 1000,
        });
        if (token) {
          store.dispatch("Logout").then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          });
        }
        break;
      default:
        Message({
          message: data.error,
          type: "error",
          duration: 5 * 1000,
        });
        break;
    }
  }
  return Promise.reject(error);
};
// request拦截器
service.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      console.log(error); // for debug
      Promise.reject(error);
    }
);
// respone拦截器
service.interceptors.response.use((response) => {
  console.log("response.data", response.data);
  return response.data;
}, err);

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "get",
      params: params,
    })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
  });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    service({
      url: url,
      method: "post",
      data: params,　　//这里注意下请求数据放在query params还是body中。
    })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
  });
}

export default {
  get,
  post,
};